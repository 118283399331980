import { FC, useState, useMemo, useEffect } from 'react';
import { Waku } from 'js-waku';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  PhantomWalletAdapter,
  CoinbaseWalletAdapter,
  GlowWalletAdapter,
  SlopeWalletAdapter,
  BitKeepWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { MoralisProvider } from 'react-moralis';
require('@solana/wallet-adapter-react-ui/styles.css');

import { initWaku } from 'src/utils';
import { WakuContext } from 'src/hooks';

import { ConnectWallet, Home } from './views';
import './App.css';

const App: FC = () => {
  const [waku, setWaku] = useState<Waku>();
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      new CoinbaseWalletAdapter(),
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new BitKeepWalletAdapter(),
    ],
    [network],
  );

  useEffect(() => {
    // initialize waku
    initWaku(setWaku)
      .then(() => console.log('Waku init done'))
      .catch((e) => console.log('Waku init failed ', e));
  }, []);

  return (
    <div className='App'>
      <WakuContext.Provider value={{ waku: waku }}>
        <MoralisProvider
          serverUrl='https://cakhnul92nxb.usemoralis.com:2053/server'
          appId='Hk1tGzZStKSYr3dS92pQUMa1Ime3fRBltzgpO3SB'
        >
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
              <WalletModalProvider>
                <ConnectWallet />
                <Home />
              </WalletModalProvider>
            </WalletProvider>
          </ConnectionProvider>
        </MoralisProvider>
      </WakuContext.Provider>
    </div>
  );
};

export default App;
