import { FC, ReactNode, KeyboardEvent } from 'react';
import { InputAdornment, TextField } from '@mui/material';

import { colors } from 'src/styles';

export interface ChatTextFieldProps {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  type?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  helperText?: string | number;
  inputPropsStyle?: object;
  style?: object;
  onKeyPress?: (e: KeyboardEvent<HTMLDivElement>) => void;
}

const ChatTextField: FC<ChatTextFieldProps> = ({
  value,
  onChange,
  placeholder,
  label,
  type,
  startAdornment,
  endAdornment,
  helperText,
  inputPropsStyle,
  style = { margin: '0.5em auto', borderRadius: 10 },
  onKeyPress,
  ...props
}) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      label={label}
      type={type}
      helperText={helperText}
      InputProps={{
        startAdornment: <InputAdornment position='start'>{startAdornment}</InputAdornment>,
        endAdornment: <InputAdornment position='end'>{endAdornment}</InputAdornment>,
        style: inputPropsStyle,
        sx: {
          color: '#fff',
          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
            border: '0px',
          },
          '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '0px',
          },
          '&:hover': {
            '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
              border: '0px',
            },
          },
          backgroundColor: colors.dark[1],
        },
      }}
      style={style}
      onKeyPress={onKeyPress}
      {...props}
    />
  );
};

export default ChatTextField;
