import { FC, useState, useEffect, useReducer, memo } from 'react';
import { Grid } from '@mui/material';
import { Waku, WakuMessage, PageDirection } from 'js-waku';
import { useWallet } from '@solana/wallet-adapter-react';

import { Message, ChatMessage } from 'src/utils';
import { useWaku } from 'src/hooks';

import ChatHeader from './ChatHeader';
import ChatHistory from './ChatHistory';
import MessageInput from './MessageInput';
import { NFT } from '../Home/Home';

export interface ChatRoomProps {
  nft: NFT;
}

function reduceMessages(state: Message[], newMessages: Message[]) {
  return state.concat(newMessages);
}

const ChatRoom: FC<ChatRoomProps> = ({ nft }) => {
  const [messages, dispatchMessages] = useReducer(reduceMessages, []);
  const [historicalMsgRetrieved, setHistoricalMsgRetrieved] = useState<boolean>(false);
  const { publicKey } = useWallet();

  const { waku } = useWaku();
  const contentTopic = `/chataverse/1/mvp-testing-${nft.symbol}/proto`;

  useEffect(() => {
    if (!waku) return;
    // Let's retrieve previous messages before listening to new messages
    if (!historicalMsgRetrieved) return;

    const handleRelayMessage = (wakuMsg: WakuMessage) => {
      console.log('Message received: ', wakuMsg);
      const msg = Message.fromWakuMessage(wakuMsg);
      if (msg) {
        dispatchMessages([msg]);
      }
    };

    waku.relay.addObserver(handleRelayMessage, [contentTopic]);

    return function cleanUp() {
      waku.relay.deleteObserver(handleRelayMessage, [contentTopic]);
    };
  }, [waku, historicalMsgRetrieved]);

  useEffect(() => {
    if (!waku) return;
    if (historicalMsgRetrieved) return;

    const retrieveMessages = async () => {
      // await waku.waitForRemotePeer();
      console.log('Retrieving archived messages');

      try {
        retrieveStoreMessages(waku, contentTopic, dispatchMessages).then((length) => {
          console.log('Messages retrieved:', length);
          setHistoricalMsgRetrieved(true);
        });
      } catch (e) {
        console.log('Error encountered when retrieving archived messages', e);
      }
    };

    retrieveMessages();
  }, [waku, historicalMsgRetrieved]);

  const sendMessage = async (message: string) => {
    if (waku) {
      const timestamp = new Date();
      const chatMessage = ChatMessage.fromUtf8String(
        timestamp,
        publicKey?.toBase58() ?? '',
        message,
      );
      const wakuMsg = await WakuMessage.fromBytes(chatMessage.encode(), contentTopic, {
        timestamp,
      });

      return waku.relay.send(wakuMsg);
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <Grid container direction='column' style={{ height: '100%' }}>
        <Grid item xs={1.5}>
          <ChatHeader title={nft.symbol} />
        </Grid>

        <Grid item xs={9.3} style={{ overflowY: 'scroll' }}>
          <ChatHistory messages={messages} />
        </Grid>

        <Grid item xs={1.2}>
          <MessageInput sendMessage={sendMessage} />
        </Grid>
      </Grid>
    </div>
  );
};

const retrieveStoreMessages = async (
  waku: Waku,
  contentTopic: string,
  setArchivedMessages: (value: Message[]) => void,
): Promise<number> => {
  const callback = (wakuMessages: WakuMessage[]): void => {
    const messages: Message[] = [];
    wakuMessages
      .map((wakuMsg) => Message.fromWakuMessage(wakuMsg))
      .forEach((message) => {
        if (message) {
          messages.push(message);
        }
      });
    setArchivedMessages(messages);
  };

  const startTime = new Date();
  // Only retrieve a week of history
  startTime.setTime(Date.now() - 1000 * 60 * 60 * 24 * 7);

  const endTime = new Date();

  try {
    const res = await waku.store.queryHistory([contentTopic], {
      pageSize: 5,
      pageDirection: PageDirection.FORWARD,
      timeFilter: {
        startTime,
        endTime,
      },
      callback,
    });

    return res.length;
  } catch (e) {
    console.log('Failed to retrieve messages', e);
    return 0;
  }
};

export default memo(ChatRoom);
