import { Waku, getPredefinedBootstrapNodes, discovery } from 'js-waku';

export const initWaku = async (setter: (waku: Waku) => void) => {
  try {
    const waku = await Waku.create({
      libp2p: {
        config: {
          pubsub: {
            enabled: true,
            emitSelf: true,
          },
        },
      },
      bootstrap: {
        peers: getPredefinedBootstrapNodes(discovery.predefined.Fleet.Prod),
      },
    });

    await waku.waitForRemotePeer();

    setter(waku);
  } catch (e) {
    console.log('Issue starting waku ', e);
  }
};
