import { FC, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';
import { useMoralisSolanaApi } from 'react-moralis';

import { colors } from 'src/styles';

import ChatNavBar from '../ChatNavBar';
import ChatRoom from '../ChatRoom';
import { HomeContainer, Title } from './styles';

type NetworkWithDev = 'mainnet' | 'devnet';
type NetworkWithTest = 'mainnet' | 'testnet';
export interface NFT {
  mint: string;
  standard: string;
  name: string;
  symbol: string;
  metaplex: {
    metadataUri: string;
    masterEdition: boolean;
    isMutable: boolean;
    primarySaleHappened: boolean;
    sellerFeeBasisPoints: number;
    updateAuthority: string;
  };
}

const Home: FC = () => {
  const { connected, connecting, publicKey } = useWallet();
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [nft, setNft] = useState<NFT>();

  const { account, SolanaAPI } = useMoralisSolanaApi();

  useEffect(() => {
    async function getNFTs() {
      if (publicKey) {
        // get SPL NFT mint addresses given a user public key
        const nftsMetadata = await account.getNFTs({
          network: 'mainnet' as NetworkWithTest,
          address: publicKey.toBase58(),
        });

        // get SPL NFT metadata
        const nfts = await Promise.all(
          nftsMetadata.map((nft) =>
            SolanaAPI.nft.getNFTMetadata({
              network: 'mainnet' as NetworkWithDev,
              address: nft.mint,
            }),
          ),
        );
        setNfts(nfts);
        if (nfts.length) {
          setNft(nfts[0]);
        }
      }
    }

    getNFTs();
  }, [connected]);

  return (
    <HomeContainer>
      {!connected && !connecting && <Title>Connect a wallet to continue 🥳</Title>}

      {connected && nft && (
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={1} style={{ height: '100%', alignSelf: 'stretch' }}>
            <ChatNavBar nfts={nfts} selectedNft={nft} setNft={setNft} />
          </Grid>
          <Grid
            item
            xs={11}
            style={{
              height: '100%',
              alignSelf: 'stretch',
              marginBottom: 'auto',
              backgroundColor: colors.dark[2],
            }}
            p='0 1em'
          >
            <ChatRoom nft={nft} />
          </Grid>
        </Grid>
      )}

      {connected && !connecting && !nfts && <Title>No NFT found with this wallet.</Title>}
    </HomeContainer>
  );
};

export default Home;
