import { FC, useState } from 'react';

import { ChatTextField } from 'src/components';

export interface MessageInputProps {
  sendMessage: (message: string) => Promise<void>;
}

const MessageInput: FC<MessageInputProps> = ({ sendMessage }) => {
  const [newMessage, setNewMessage] = useState<string>('');

  const onMessageSend = async () => {
    await sendMessage(newMessage);
    setNewMessage('');
  };

  return (
    <ChatTextField
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      style={{ width: '100%', fontSize: '16px' }}
      inputPropsStyle={{ height: '44px' }}
      placeholder='Message...'
      onKeyPress={async (e) => {
        if (e.key === 'Enter') {
          await onMessageSend();
        }
      }}
    />
  );
};

export default MessageInput;
