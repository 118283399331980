import { FC } from 'react';
import { Grid, IconButton } from '@mui/material';

import { colors } from 'src/styles';

import { NFT } from '../Home/Home';
import RoomLogo from './RoomLogo';
import { ChatNavBarContainer } from './styles';

export interface ChatNavBarProps {
  selectedNft: NFT;
  nfts: NFT[];
  setNft: (nft: NFT) => void;
}

const ChatNavBar: FC<ChatNavBarProps> = ({ selectedNft, nfts, setNft }) => {
  const onRoomClick = (nft: NFT) => {
    setNft(nft);
  };

  // get a list of unique collections because each room represents a collection
  const uniqueCollections = Object.fromEntries(
    [...new Set(nfts.map((nft) => nft.symbol))].map((symbol) => [symbol, false]),
  );
  const nftCollections: NFT[] = [];
  nfts.forEach((nft) => {
    if (!uniqueCollections[nft.symbol]) {
      nftCollections.push(nft);
      uniqueCollections[nft.symbol] = true;
    }
  });

  const rooms = nftCollections.map((nft) => (
    <Grid
      item
      key={nft.name}
      sx={{
        backgroundColor: selectedNft.mint === nft.mint ? '#4B4B4B' : colors.dark[1],
        width: '100%',
      }}
    >
      <IconButton onClick={() => onRoomClick(nft)} sx={{ padding: 1 }}>
        <RoomLogo nft={nft} />
      </IconButton>
    </Grid>
  ));

  return (
    <ChatNavBarContainer>
      <Grid container>{rooms}</Grid>
    </ChatNavBarContainer>
  );
};

export default ChatNavBar;
