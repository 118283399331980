import { FC } from 'react';
import { Grid } from '@mui/material';

import { colors } from 'src/styles';

import CircularIcon from '../CircularIcon';

export interface ChatMessageProps {
  name: string;
  address: string;
  message: string;
  timestamp: string | number | Date;
  userIcon?: string;
  isSentByUser: boolean;
}

const ChatMessage: FC<ChatMessageProps> = ({
  name,
  address,
  message,
  timestamp,
  userIcon,
  isSentByUser,
}) => {
  return (
    <div style={{ padding: '0.3em 0' }}>
      <Grid
        container
        direction={isSentByUser ? 'row-reverse' : 'row'}
        style={{ textAlign: isSentByUser ? 'right' : 'left' }}
      >
        <Grid item xs={0.7}>
          <CircularIcon src={userIcon} alt='' width='36px' />
        </Grid>
        <Grid item xs={11.3}>
          {!isSentByUser ? <div style={{ color: 'grey' }}>{name}</div> : null}

          <div style={{ margin: '8px 0' }}>
            <span
              style={{
                // padding: '0.5em',
                maxWidth: '650px',
                // backgroundColor: colors.dark[1],
                borderRadius: '4px',
              }}
            >
              {message}
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatMessage;
