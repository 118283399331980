import { styled } from '@mui/material';

import { colors } from 'src/styles';

export const HomeContainer = styled('div')({
  width: '900px',
  height: '600px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  '-webkit-transform': 'translate(-50%, -50%)',
  transform: 'translate(-50%, -50%)',
  backgroundColor: colors.dark[1],
  borderRadius: '10px',
});

export const Title = styled('h2')({
  marginTop: '30%',
});
