import { memo, FC } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';

import { ChatMessage, AlwaysScrollToBottom } from 'src/components';
import { getPng } from 'src/components/icons';
import { Message } from 'src/utils';

const ChatHistory: FC<{ messages: Message[] }> = ({ messages }) => {
  const { publicKey } = useWallet();

  const messageList = messages.map((msg, index) => (
    <ChatMessage
      key={index}
      name={msg.nick}
      address={''}
      message={msg.payloadAsUtf8}
      timestamp={msg.timestamp}
      userIcon={getPng('fake-user-2.png')}
      isSentByUser={publicKey?.toBase58() === msg.nick}
    />
  ));

  return (
    <div>
      <div style={{ padding: '0 0.5em 1em' }}>{messageList}</div>
      <AlwaysScrollToBottom messages={messages} />
    </div>
  );
};

export default memo(ChatHistory);
