import { useEffect, useRef } from 'react';

const AlwaysScrollToBottom = ({ ...props }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    elementRef.current?.scrollIntoView();
  }, [props]);

  return <div>{elementRef && <div ref={elementRef} />}</div>;
};

export default AlwaysScrollToBottom;
