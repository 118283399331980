import { FC } from 'react';

export interface ChatHeaderProps {
  title: string;
}

const ChatHeader: FC<ChatHeaderProps> = ({ title }) => {
  return (
    <div style={{ textAlign: 'left' }}>
      <h4>{title}</h4>
    </div>
  );
};

export default ChatHeader;
