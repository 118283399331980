import { FC, useState, useEffect, memo } from 'react';
import axios from 'axios';

import { RoundedIcon } from 'src/components';

import { NFT } from '../Home/Home';

export interface RoomLogo {
  nft: NFT;
}

const RoomLogo: FC<RoomLogo> = ({ nft }) => {
  const [nftMetadata, setNftMetadata] = useState<any>();

  useEffect(() => {
    async function getMetadata() {
      const res = await axios.get(nft.metaplex.metadataUri);
      const data = res.data;
      setNftMetadata(data);
    }

    getMetadata();
  }, []);

  return (
    <RoundedIcon
      src={nftMetadata?.image}
      alt=''
      width='48px'
      height='48x'
      style={{ backgroundColor: 'black' }}
    />
  );
};

export default memo(RoomLogo);
