import { FC } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import { ConnectWalletContainer } from './styles';

const ConnectWallet: FC = () => {
  return (
    <ConnectWalletContainer>
      <WalletMultiButton />
    </ConnectWalletContainer>
  );
};

export default ConnectWallet;
